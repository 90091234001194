import API from '../config/API';

const getClientInfoReq = async params => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params)
  };
  const response = await fetch(API.getClientInfoUrl, requestOptions);
  const data = await response.json();
  return data;
};

export default getClientInfoReq;
