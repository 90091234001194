import React, { useState, useEffect } from 'react';
import optOutReq from '../services/optOutReq';
import getClientInfoReq from '../services/getClientInfoReq';

const Form = () => {
  const [customer, setCustomer] = useState();
  const [campaign, setCampaign] = useState();
  const [countryCode, setcountryCode] = useState('');
  const [img, setImg] = useState();
  const [reqResponse, setReqResponse] = useState();

  const handleSubmit = async event => {
    event.preventDefault();
    const resp = await optOutReq({
      cx: customer,
      phone: countryCode + event.target.phone.value,
      cmp: campaign
    });
    setReqResponse(resp);
  };

  const getParams = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const cx = params.get('cx');
    const client = await getClientInfoReq({
      cx: cx
    });
    setcountryCode(client.countryCode);
    setCustomer(cx);

    setCampaign(params.get('cmp'));
    setImg(`https://media-rcs.s3.eu-west-3.amazonaws.com/optout-${cx}.jpg`);
  };

  useEffect(() => {
    getParams();
  }, []);

  return (
    <div className="flex justify-center pt-12 font-mono">
      <div className="flex p-6 border-2 border-black">
        <div className="flex-none w-48 mb-10 relative z-10">
          <img src={img} className="absolute" />
        </div>
        <form className="flex-auto pl-6" onSubmit={handleSubmit}>
          <div className="relative flex flex-wrap items-baseline pb-6 before:bg-black before:absolute before:-top-6 before:bottom-0 before:-left-60 before:-right-6">
            <h1 className="relative w-full flex-none mb-2 text-2xl font-semibold text-white">
              {customer}
            </h1>
            <div className="relative text-lg text-white">
              Leíratkozás az sms kampányokról
            </div>
            <div className="relative uppercase text-teal-400 ml-3">SMS</div>
          </div>
          <div className="flex items-baseline mt-6 mb-3">
            <div className="space-x-3 flex text-sm font-medium">
              <input
                className="px-6 h-12 uppercase font-semibold tracking-wider border-2 border-silver text-black"
                type="text"
                name="phone"
                placeholder="A telefonszámod"
                required={true}
                minLength={8}
                maxLength={9}
              />
            </div>
          </div>

          <div className="flex space-x-2 mb-4 text-sm font-medium">
            <div className="flex space-x-4">
              {reqResponse !== 'success' ? (
                <button
                  type="submit"
                  className="px-6 h-12 uppercase font-semibold tracking-wider border-2 border-black bg-teal-400 text-black cursor-pointer"
                >
                  Szeretnék leíratkozni
                </button>
              ) : (
                <button
                  type="submit"
                  className="px-6 h-12 uppercase font-semibold tracking-wider border-2 border-black grey-400 text-black cursor-auto"
                >
                  👌🏼 Leíratkoztál
                </button>
              )}
            </div>
          </div>
          <p className="text-xs leading-6 text-slate-500">
            Nem fogsz több sms-t kapni a {customer}tól
          </p>
        </form>
      </div>
    </div>
  );
};

export default Form;
